// Aqui se centralizan los strings de las rutas al API
export class API {
    public static webApiBase = process.env.VUE_APP_ROOT_API;
    public static webApiBaseImages = process.env.VUE_APP_ROOT_PATH+'Images/';
    public static webApiBaseProyectos = process.env.VUE_APP_ROOT_PATH+'proyectos/';
    public static webApiBaseTareas = process.env.VUE_APP_ROOT_PATH+'tareas/';
    public static webApiBaseIncidencias = process.env.VUE_APP_ROOT_PATH+'incidencias/';
    public static webApiBaseinventario = process.env.VUE_APP_ROOT_PATH+'inventario/';
    
    public static login = API.webApiBase + 'Login';
    public static usuarios = API.webApiBase + 'Usuarios';
    public static roles = API.webApiBase + 'Roles';
    public static VersionBack = API.webApiBase + 'VersionBack';
    /*************************** */
    public static departamentos = API.webApiBase + 'departamentos';
    public static empresas = API.webApiBase + 'empresas';
    public static SendingEmails = API.webApiBase + 'SendingEmails';
    public static servidor_smtp = API.webApiBase + 'servidor_smtp';
    public static usuario = API.webApiBase + 'usuarios';
    public static pantalla_lista = API.webApiBase + 'pantalla_lista';
    public static proyecto = API.webApiBase + 'proyecto';
    public static enumeracion = API.webApiBase + 'enumeraciones';
    public static incidencia = API.webApiBase + 'incidencia';
    public static tarea = API.webApiBase + 'tareas';
    public static grupo_usuario = API.webApiBase + 'grupo_usuarios';
    public static mensajes_chat = API.webApiBase + 'mensajes_chat';
    public static Connect = API.webApiBase + 'Connect';
    public static cambioscontrasenasperdidas = API.webApiBase + 'cambioscontrasenasperdidas';
    public static categoria_invetario = API.webApiBase + 'categoria_invetario';
    public static invetario = API.webApiBase + 'inventario';
    public static rol = API.webApiBase + 'roles';
    public static mantenimiento_permiso = API.webApiBase + 'mantenimiento_permisos';
    public static permiso = API.webApiBase + 'permiso';
    public static DashBoard = API.webApiBase + 'DashBoard';
    public static TestAvatar = API.webApiBase + 'TestAvatar';
    public static plantillas_cabezera = API.webApiBase + 'plantillas_cabezera';
    public static plantilla_tareas = API.webApiBase + 'plantilla_tareas';
    public static periodicidad_plantilla_tarea = API.webApiBase + 'periodicidad_plantilla_tareas';
    public static mediciones_inventario = API.webApiBase + 'mediciones_inventario';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';



}
